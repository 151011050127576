import React, { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Save, Clock, Trash2 } from 'lucide-react'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeCreate from '../api/authorizeCreate'
import authorizePost from '../api/authorizePost'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

const CreateJob = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [value, setValue] = useState('')
  const [data, setData] = useState([])
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [location, setLocation] = useState('')
  const [deadline, setDeadline] = useState('')

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authorizeGetRequest('countries')
        setData(response)
      } catch (error) {
        console.log('Error fetching countries', error)
        toast.error('Error fetching countries')
      }
    }
    getData()
  }, [])

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleCreateJob = async (e) => {
    e.preventDefault()

    try {
      await authorizeCreate('job', {
        title,
        subTitle,
        description: value,
        location: parseInt(location),
        deadline: new Date(`${deadline}T23:59:59.032Z`),
        status: false,
      })
      console.log('Job created successfully')
      navigate('/jobs')
    } catch (error) {
      console.log('Error creating job', error)
      toast.error('Error creating job')
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="content2">
            <div>
              <div className="md:flex md:gap-4">
                <section className="md:w-8/12 bg-white">
                  <div className="p-4">
                    <h4 className="card-title">Create new Job</h4>
                    <form onSubmit={handleCreateJob}>
                      <div className="flex gap-x-4">
                        <div className="w-full border-b-2 border-gray-500 mb-4">
                          <label htmlFor="sub-title" className="font-bold">
                            Title
                          </label>
                          <input
                            type="text"
                            placeholder="Add Title"
                            className="w-full"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="w-full border-b-2 border-gray-500 mb-4">
                          <label htmlFor="sub-title" className="font-bold">
                            Sub Title
                          </label>
                          <input
                            type="text"
                            placeholder="Add Sub Title"
                            className="w-full"
                            value={subTitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex gap-x-4">
                        <div className="w-full border-b-2 border-gray-500 mb-4 ">
                          <label htmlFor="sub-title" className="font-bold">
                            Location
                          </label>
                          <select
                            className="w-full"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          >
                            <option value="">Select Country</option>
                            {data.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="w-full border-b-2 border-gray-500 mb-4">
                          <label htmlFor="sub-title" className="font-bold">
                            Deadline
                          </label>
                          <input
                            type="date"
                            className="w-full"
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="h-[65vh] md:h-[55vh]">
                        <ReactQuill
                          modules={modules}
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          className="h-[40vh]"
                        />
                      </div>

                      <button
                        type="submit"
                        className="py-2 px-8 font-bold  shadow-md rounded-lg text-white  bg-[#ef6603] w-full md:w-36"
                      >
                        Post Job
                      </button>
                    </form>
                  </div>
                </section>
                <div className="flex w-full md:items-end md:w-4/12">
                  <section className="w-full h-[30vh] bg-white ">
                    <div className="">
                      <h4 className="card-title w-full bg-[#d8c0dd] text-white p-3">
                        More options
                      </h4>
                      <div className="px-2 ">
                        <div className="w-full grid">
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg py-2 px-6 my-1 gap-x-2">
                            <Save size={20} />
                            Save Draft
                          </button>
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                            <Clock size={20} />
                            Schedule
                          </button>
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                            <Trash2 size={20} />
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default CreateJob
